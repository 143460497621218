<template>
  <div class="container mt-1">
    <div class="line-gradient" />
    <h3 class="text-center text-warning">
      สล็อต ออนไลน์
    </h3>
    <div class="line-gradient line-h" />

    <b-row>
      <b-col
        md="6"
        cols="12"
        class="mt-2"
      >
        <div
          class="hover14"
          @click="joker"
        >
          <figure>
            <!-- <div class="img_info"> -->
            <b-img
              fluid
              src="/game/play/new_13.png"
              alt="joker game"
            />
            <!-- </div> -->
          </figure>
        </div>
      </b-col>
      <b-col
        md="6"
        cols="12"
        class="mt-2"
      >
        <div
          class="hover14"
          @click="pg"
        >
          <figure>
            <b-img
              fluid
              src="/game/play/new_2.png"
              alt="pg game"
            />
          </figure>
        </div>
      </b-col>
    </b-row>
    <!-- <div class="text-center mt-2">

      <div class="img_info">
        <b-img
          fluid
          src="/game/play/new_2.png"
          alt="joker game"
        />
      </div>
    </div> -->

    <div class="line-gradient mt-3" />
    <h3 class="text-center text-warning">
      คาสิโน ออนไลน์
    </h3>
    <div class="line-gradient line-h" />

    <b-row>
      <b-col
        md="4"
        cols="6"
        class="mt-2"
      >
        <div
          class="hover14"
          @click="playgame('SaGaming')"
        >
          <figure>
            <b-img
              src="/casino/sa.png"
              alt=""
              class="img_casino"
              fluid
            />
          </figure>
        </div>
      </b-col>

      <b-col
        md="4"
        cols="6"
        class="mt-2"
      >
        <div
          class="hover14"
          @click="playgame('SexyBaccarat')"
        >
          <figure>
            <b-img
              src="/casino/sexy.png"
              alt=""
              class="img_casino"
              fluid
            />
          </figure>
        </div>
      </b-col>

      <b-col
        md="4"
        cols="6"
        class="mt-2"
      >
        <div
          class="hover14"
          @click="playgame('AG')"
        >
          <figure>
            <b-img
              src="/casino/ag.png"
              alt=""
              class="img_casino"
              fluid
            />
          </figure>
        </div>
      </b-col>

      <b-col
        md="4"
        cols="6"
        class="mt-2"
      >
        <div
          class="hover14"
          @click="playgame('AllBet')"
        >
          <figure>
            <b-img
              src="/casino/allbet.png"
              alt=""
              class="img_casino"
              fluid
            />
          </figure>
        </div>
      </b-col>

      <b-col
        md="4"
        cols="6"
        class="mt-2"
      >
        <div
          class="hover14"
          @click="playgame('EBet')"
        >
          <figure>
            <b-img
              src="/casino/ebet.png"
              alt=""
              class="img_casino"
              fluid
            />
          </figure>
        </div>
      </b-col>

      <b-col
        md="4"
        cols="6"
        class="mt-2"
      >
        <div
          class="hover14"
          @click="playgame('Evo')"
        >
          <figure>
            <b-img
              src="/casino/evo.png"
              alt=""
              class="img_casino"
              fluid
            />
          </figure>
        </div>
      </b-col>

      <b-col
        md="4"
        cols="6"
        class="mt-2"
      >
        <div
          class="hover14"
          @click="playgame('PrettyBaccarat')"
        >
          <figure>
            <b-img
              src="/casino/pt.png"
              alt=""
              class="img_casino"
              fluid
            />
          </figure>
        </div>
      </b-col>

      <b-col
        md="4"
        cols="6"
        class="mt-2"
      >
        <div
          class="hover14"
          @click="playgame('BigGame')"
        >
          <figure>
            <b-img
              src="/casino/pg.png"
              alt=""
              class="img_casino"
              fluid
            />
          </figure>
        </div>
      </b-col>

      <b-col
        md="4"
        cols="6"
        class="mt-2"
      >
        <div
          class="hover14"
          @click="playgame('JL')"
        >
          <figure>
            <b-img
              src="/casino/ion.png"
              alt=""
              class="img_casino"
              fluid
            />
          </figure>
        </div>
      </b-col>
    </b-row>
    <br><br><br><br><br><br>
  </div>
</template>

<script>
import { BRow, BCol, BImg } from 'bootstrap-vue'
import BUID from 'uniquebrowserid'

export default {
  components: {
    BRow,
    BCol,
    BImg,
  },
  data() {
    return {
      show: true,
    }
  },
  mounted() {
    // this.getgamelistall()
    this.updateBUID()
  },
  methods: {
    updateBUID() {
      const buid = new BUID().completeID()
      const formData = {
        buid,
      }
      this.$http.post('https://api.ufabangkok.com/api/users/updatebuid', formData)
    },
    joker() {
      this.$router.push({ name: 'gamesjoker' })
    },
    pg() {
      this.SwalError('เปิดให้บริการเร็วๆ นี้')
    },
    playgame(GameCode) {
      this.$router.push({ name: 'play', params: { id: GameCode } })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'info',
        title: '<h3 style="color: #000">Coming soon</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style>
.img_info {
  padding: 10px 0px;
}

.img_game {
  border-radius: 15px;
  max-width: 350px;
  /* max-height: 240px; */
  background-color: black;
}
.bg {
  background-position: center center;
  /* background-color: rgb(255, 255, 255); */
  background-size: cover;
  background-image: url(https://vipauto88.com/customer_asset/images/member/bg-member.jpg);
}
.img_game2 {
  margin-top: 20px;
  border-radius: 15px;
  max-width: 350px;
  /* height: 240px; */
  background-color: black;
}

.img_casino {
  border-radius: 10px;
}

.line-gradient {
  width: 100%;
  height: 2px;
  background: linear-gradient(
    to right,
    transparent,
    #f1c666,
    #fbdd85,
    #ffcc68,
    transparent
  );
}
.line-h {
  margin-top: -10px;
}

figure {
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  background: rgba(0, 0, 0, 0);
  overflow: hidden;
}
figure:hover + span {
  bottom: -36px;
  opacity: 1;
}

.hover14 figure {
  position: relative;
}
.hover14 figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.hover14 figure:hover::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
</style>
